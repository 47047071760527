<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";

/**
 * Add Product Component
 */
export default {

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      form: {
        name: "",
        categoryId: "",
        warehouseId: "",
        priceNetto: 0,
        priceBrutto: 0,
        taxId: "",
        stock: 0,
        ean: "",
        description: "",
        deliveryCost: 0,
        deliveryTime: 0,
        deliveryUnit: "",
        pictureUrl: "",

        pictureFile: "",
        extension: ""
      },

      deliveryUnits: null

    };
  },

  validations: {

    form: {
      name: { required },
      description: { required },
      categoryId: { required },
      taxId: { required },
      warehouseId: { required },
      ean: { required },
      stock: { required },
      priceNetto: { required },
      priceBrutto: { required },
      pictureUrl: { required }
    }

  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.add-product.title'),
          active: true
        }
      ]
    },

    async loadDeliveryUnits() {
      try {
        const result = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnits = result.data.deliveryUnits
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    selectImage(input) {
      const image = input.target.files[0];
      this.form.pictureFile = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        const dataUrl = event.target.result;

        this.form.pictureUrl = dataUrl;
        this.form.extension = dataUrl.split(';')[0].split('/')[1];
      };
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.form.pictureFile);
      formData.append('extension', this.form.extension)

      try {
        const result = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.form.pictureUrl = result.data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async addProduct() {
      this.submitted = true
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (this.form.pictureFile) {
        await this.upload()
      }

      const { name, description, categoryId, taxId, warehouseId, ean, deliveryCost, deliveryTime, deliveryUnit, stock, priceNetto, priceBrutto } = this.form

      const json = JSON.stringify({
        "name": name,
        "description": description,
        "categoryId": categoryId,
        "taxId": taxId,
        "warehouseId": warehouseId,
        "pictureUrl": this.form.pictureUrl,
        "ean": ean,
        "deliveryCost": deliveryCost,
        "deliveryTime": deliveryTime,
        "deliveryUnit": deliveryUnit,
        "stock": stock,
        "priceNetto": priceNetto,
        "priceBrutto": priceBrutto
      })

      axios.put(`/product`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        Swal.fire("Sukces!", "Nowy produkt został dodany", "success").then(() => {
          window.location = `/dashboard/admin/product/show/${data.id}`
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    }

  },

  async created() {
    await this.loadDeliveryUnits()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.add-product.title')" :items="getItems()" />
    <div class="row">
      <div class="col-3">
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <form>

              <div class="form-group">
                <label>Nazwa produktu</label>
                <input v-model="form.name" class="form-control" type="text" :class="{ 'is-invalid': submitted && $v.form.name.$error }" />
                <div v-if="!$v.form.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <ecat-multiselect @change="value => form.categoryId = value"
                                  view-id="name"
                                  save-id="id"
                                  :can-unselect="true"
                                  label="Kategoria"
                                  placeholder="Wybierz kategorie"
                                  load-url="/category/list/pagination"
                                  query-url="/category/by-name"
                                  param="name"
                                  :class="{ 'is-invalid': submitted && $v.form.categoryId.$error }"
                />
                <div v-if="!$v.form.categoryId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <ecat-multiselect @change="value => form.warehouseId = value"
                                  save-id="id"
                                  view-id="name"
                                  label="Hurtownia"
                                  placeholder="Wybierz hurtownie"
                                  load-url="/warehouse/list/pagination"
                                  query-url="/warehouse/by-name"
                                  param="name"
                                  :can-unselect="true"
                                  :class="{ 'is-invalid': submitted && $v.form.warehouseId.$error }"
                />
                <div v-if="!$v.form.warehouseId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <label>Cena hurtowa netto</label>
                <input v-model="form.priceNetto" class="form-control" type="number" :class="{ 'is-invalid': submitted && $v.form.priceNetto.$error }" />
                <div v-if="!$v.form.priceNetto.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <label>Cena sprzedaży</label>
                <input v-model="form.priceBrutto" class="form-control" type="number" :class="{ 'is-invalid': submitted && $v.form.priceBrutto.$error }" />
                <div v-if="!$v.form.priceBrutto.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <ecat-multiselect
                    @change="value => form.taxId = value"
                    save-id="id"
                    label="Podatek"
                    placeholder="Wybierz stawke VAT"
                    load-url="/tax/list/pagination"
                    query-url="/tax/by-name"
                    param="name"
                    :custom-label="value => value.rate + '%'"
                    :class="{ 'is-invalid': submitted && $v.form.taxId.$error }"
                />
                <div v-if="!$v.form.taxId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <label>Liczba sztuk</label>
                <input v-model="form.stock" class="form-control" type="number" :class="{ 'is-invalid': submitted && $v.form.stock.$error }" />
                <div v-if="!$v.form.stock.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <label>EAN</label>
                <input v-model="form.ean" class="form-control" type="text" :class="{ 'is-invalid': submitted && $v.form.ean.$error }" />
                <div v-if="!$v.form.ean.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <label>Opis</label>
                <textarea v-model="form.description" class="form-control" type="text" :class="{ 'is-invalid': submitted && $v.form.description.$error }" />
                <div v-if="!$v.form.description.required" class="invalid-feedback">{{ $t('message.required')}}</div>
              </div>

              <div class="form-group">
                <label>Koszt dostawy</label>
                <input v-model="form.deliveryCost" class="form-control" type="number" />
              </div>

              <div class="form-group">
                <label>Czas dostawy</label>
                <input v-model="form.deliveryTime" class="form-control" type="number" />
              </div>

              <div class="form-group">
                <label>Jednostka czasu</label>
                <select v-model="form.deliveryUnit" class="custom-select">
                  <option v-for="(unit, index) in deliveryUnits" :key="index" :value="unit.deliveryUnit" >{{ unit.name }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Obrazek</label>

                <div class="text-center" v-if="form.pictureUrl">
                  <img class="avatar-lg align-self-center rounded mr-3" :src="form.pictureUrl" />
                </div>

                <div class="button-items mb-0">
                  <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="send-picture" @change="selectImage" hidden/>
                  <label for="send-picture" class="btn btn-primary">{{ $t('message.send') }}</label>
                </div>

                <div v-if="!$v.form.pictureUrl.required" class="text-danger">{{ $t('message.required')}}</div>

                <p class="font-size-12">{{ $t('profile.allowed-file-types')}}</p>
              </div>
            </form>

            <div class="button-items">
              <b-button variant="success" @click="addProduct">Dodaj produkt</b-button>

              <div class="float-right">
                <b-button variant="primary" href="/dashboard/admin/products">Wróć do listy produtków</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
      </div>
    </div>

  </Layout>
</template>